@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Inter:wght@300;400;600;700&family=Montserrat:wght@300;400;500;600;700&family=Source+Code+Pro:wght@500;600&display=swap');

@import 'partials/mixins';
@import 'partials/color';

body, html {
  font-family: 'Inter';
  color: $defaultFont;
  font-weight: 400;
  
  p {
    font-family: 'Inter';
    margin: 8px 0;
  }

  ul {
    margin: 8px 0;
    li {
      margin-bottom: 6px;
    }
  }
  
  h1, h2, h3, h4, h5 {
    font-family: 'Inter';
    font-weight: 600;
    margin: 8px 0;
  }

  h1 {
    font-size: 3em;
    line-height: 1;
  }
  h3 {
    font-size: 1.5em;
    line-height: 2rem;
  }
  h4 {
    font-size: 1.24em;
  }

  .bg-primary {
    color: $white;
    background-color: $primary;
  }

}

.footer{
  padding: 16px;
}

.bg-gradient {
  background-image: url('./../images/gradient.svg');
  background-repeat: repeat-x;
}

.bullet {
  &.list-inside li {
    list-style-position: inside;
  }

  li {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 18px;
    line-height: 1.7em;
  }  
}

ol.number {
  &.list-inside li {
    list-style-position: inside;
  }

  li {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left: 18px;
    line-height: 1.7em;
  }  
}

